<template>
  <div class="page-div">
    <div class="line-div" v-for="(item, index) in info" :key="index" @click="routerSkip(index)">
      <div class="line-div_name">{{ item.name }}</div>
      <img class="line-img_right" src="@/assets/images/app/right.png" />
    </div>
  </div>
</template>

<script>
  import { List as infoData } from './data/PersonalInfoCollection';
  export default {
    data() {
      return {
        info: infoData,
      };
    },

    methods: {
      routerSkip(index) {
        // 跳转kind页面详情列表
        window.location.href = window.location.origin + '/personal-info-collect-ba/kind?id=' + index;
      },
    },
  };
</script>
<style scoped>
  .line-img_right {
    width: 0.32rem;
    height: 0.32rem;
  }

  .page-div {
    display: flex;
    flex-flow: column nowrap;
    margin: auto 0.3rem;
  }

  .line-div_name {
    font-size: 0.32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }

  .line-div {
    height: 1rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    border-bottom: 0.01rem solid #ebebeb;
  }
</style>
